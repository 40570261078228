import { Component, OnInit } from '@angular/core';
import { RoutesContants } from '../../constants/routes.constant';
import { UtilService } from '../../services/util.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  isLogin: boolean;
  userTypeId: string;
  formRoutes = RoutesContants.formRoutes;

  constructor(
    private _us: UtilService
  ) {
    this.isLogin = this._us.isUserLogin();
    this.userTypeId = this._us.getUserType();
  }

  ngOnInit() {
  }

}
