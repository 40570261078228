    <!-- Main Footer -->
    <footer class="main-footer style-three">
      <div class="auto-container">
          <!--Widgets Section-->
          <div class="widgets-section">
              <div class="row">
                  <div class="big-column col-xl-12 col-lg-12 col-md-12">
                      <div class="row">
                          <div class="footer-column col-lg-3 col-md-6 col-sm-12">
                              <div class="footer-widget links-widget">
                                  <h4 class="widget-title">Company</h4>
                                  <div class="widget-content">
                                      <ul class="list">
                                          <li><a>About Us</a></li>
                                          <li><a>News</a></li>
                                          <li><a>Contact Us</a></li>
                                      </ul>
                                  </div>
                              </div>
                          </div>

                          <div class="footer-column col-lg-3 col-md-6 col-sm-12">
                              <div class="footer-widget links-widget">
                                  <h4 class="widget-title">Navigate</h4>
                                  <div class="widget-content">
                                      <ul class="list">
                                          <li *ngIf="userTypeId != '3'">
                                            <a [routerLink]="userTypeId == '2' && isLogin ? formRoutes.postJob : formRoutes.signin">
                                              Post job
                                            </a>
                                          </li>
                                          <li *ngIf="userTypeId != '2'">
                                            <a [routerLink]="formRoutes.findJobs">Find Jobs</a>
                                          </li>
                                          <li *ngIf="userTypeId != '3'">
                                            <a [routerLink]="formRoutes.findWorkers">Find Skills</a>
                                          </li>
                                      </ul>
                                  </div>
                              </div>
                          </div>

                          <div class="footer-column col-lg-3 col-md-6 col-sm-12">
                              <div class="footer-widget links-widget">
                                  <h4 class="widget-title">Popular Cities</h4>
                                  <div class="widget-content">
                                      <ul class="list">
                                          <li><a>Ballston Lake</a></li>
                                          <li><a>Brooklyn</a></li>
                                          <li><a>Cambridge</a></li>
                                          <li><a>Lake Forest</a></li>
                                      </ul>
                                  </div>
                              </div>
                          </div>

                          <div class="footer-column col-lg-3 col-md-6 col-sm-12">
                              <div class="footer-widget">
                                  <h4 class="widget-title">Support</h4>
                                  <div class="widget-content">
                                      <ul class="list">
                                          <li><a>Trust & Safety</a></li>
                                          <li><a>Help Center</a></li>
                                      </ul>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>


      <!--Bottom-->
      <div class="footer-bottom">
          <div class="auto-container">
              <div class="outer-box">
                  <div class="copyright-text">
                    © 2021
                    <a>Skilled Services®</a>.
                    <br/>
                    <a>Privacy Policy</a>
                    - <a>Terms &amp; Condition</a>
                  </div>
                  <div class="social-links">
                      <span class="follow-text">Follow Us: </span>
                      <a><i class="fa fa-facebook-f"></i></a>
                      <a><i class="fa fa-twitter"></i></a>
                      <a><i class="fa fa-instagram"></i></a>
                      <a><i class="fa fa-linkedin"></i></a>
                  </div>
              </div>
          </div>
      </div>
      <div class="scroll-to-top scroll-to-target" data-target="html"><span class="fa fa-angle-up"></span></div>
  </footer>
  <!-- End Main Footer -->
