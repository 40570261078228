import { Injectable } from '@angular/core';
import { ApiConstants } from '../constants/api.constant';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(
    private http: HttpService
  ) { }

  VerifyEmailExists(email: string){
    return this.http.get(ApiConstants.routes.verifyEmailExists + email);
  }
  verifyUserName(username: string){
    return this.http.get(ApiConstants.routes.verifyUserName + username);
  }

  /* --------------- General Functions --------------- */
  getCategories() {
    return this.http.get(ApiConstants.routes.getCategoriesList);
  }
  getSubCategories() {
    return this.http.get(ApiConstants.routes.getSubCategoriesList);
  }
  getCountires() {
    return this.http.get(ApiConstants.routes.GetCountriesList);
  }
  getCities() {
    return this.http.get(ApiConstants.routes.getCitiesList);
  }
  getAreas() {
    return this.http.get(ApiConstants.routes.getAreaList);
  }
  uploadFile(data: any) {
    return this.http.post(ApiConstants.routes.Upload, data).toPromise();
  }
  addOrRemoveFavorite(data: any, isRemove: boolean) {
    const url = isRemove ? ApiConstants.routes.removeFavorite : ApiConstants.routes.addFavorite;
    return this.http.put(url, data);
  }
  // removeFavorite(data: any) {
  //   return this.http.put(ApiConstants.routes.removeFavorite, data);
  // }
  getFavorite(data: any) {
    return this.http.post(ApiConstants.routes.getFavorite, data);
  }

  /* --------------- Job Functions --------------- */

  getjobs(filter: any = {}) {
    return this.http.post(ApiConstants.routes.getJobsList, filter);
  }
  getJobTypes(withJobCount: boolean = false) {
    return this.http.get(`${ApiConstants.routes.getJobTypesList}?withJobCount=${withJobCount}`);
  }
  getJobDetailbyid(jobId: number, WithJobProposal: boolean = false) {
    const url = `${ApiConstants.routes.getJobDetailbyid}job_id=${jobId}&WithJobProposal=${WithJobProposal}`
    return this.http.get(url);
  }
  applyJob(data: any) {
    return this.http.post(ApiConstants.routes.applyJob, data);
  }
  postJob(data) {
    return this.http.post(ApiConstants.routes.postJob, data);
  }
  deleteJob(job_id: any) {
    return this.http.post(ApiConstants.routes.DeleteJob, { job_id });
  }
  getJobProposalById(proposalId: any){
    return this.http.get(ApiConstants.routes.getJobProposalById + proposalId);
  }
  // postService(data) {
  //   return this.http.put(ApiConstants.routes.postService, data);
  // }
  approveJobProposal(data: any) {
    return this.http.post(ApiConstants.routes.approveJobProposal, data);
  }
  endJob(data: any) {
    return this.http.post(ApiConstants.routes.endJob, data);
  }
  addFeedback(data: any) {
    return this.http.post(ApiConstants.routes.addFeedback, data);
  }
  addBookingGeneralInfo(data: any) {
    return this.http.post(ApiConstants.routes.addBookingServiceGeneralInfo, data);
  }
  addBookingScheduleInfo(data: any) {
    return this.http.post(ApiConstants.routes.addBookingServiceScheduleInfo, data);
  }
  addBookingPaymentMethod(data: any) {
    return this.http.post(ApiConstants.routes.addBookingServicePaymentMethod, data);
  }
  getFeaturedJobsList() {
    return this.http.get(ApiConstants.routes.getFeaturedJobsList);
  }
  getFeaturedCategoriesList() {
    return this.http.get(ApiConstants.routes.getFeaturedCategoriesList);
  }
  getFavoriteList(userId: any) {
    return this.http.get(`${ApiConstants.routes.getFavoriteList}${userId}`);
  }

  /* --------------- Provider Functions --------------- */
  getProvidersList(filters: any = {}) {
    return this.http.post(ApiConstants.routes.getProvidersList, filters);
  }
  getProvidersById(providerId: any, withJobs: boolean = false) {
    return this.http.get(`${ApiConstants.routes.getProvidersById}id=${providerId}&withJobs=${withJobs}`);
  }
  getFeaturedProvidersList() {
    return this.http.get(ApiConstants.routes.getFeaturedProvidersList);
  }

  /* --------------- User Functions --------------- */
  getUserProfileById(userId: any, userType: any) {
    const url = `${ApiConstants.routes.getUserProfileById}user_id=${userId}&user_type=${userType}`;
    return this.http.get(url);
  }

  getUserDetailbyId(userId: any, userType: any) {
    const url = `${ApiConstants.routes.getUserDetailbyId}user_id=${userId}&user_type=${userType}`;
    return this.http.get(url);
  }

  // getServicesbyProviderId(user_id) {
  //   return this.http.post(ApiConstants.routes.getServicesbyProviderId, { user_id });
  // }

  updateUserProfile(data: any) {
    return this.http.put(ApiConstants.routes.updateUserProfile, data);
  }



}
