import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { UtilService } from '../services/util.service';
import { RoutesContants } from '../constants/routes.constant';

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {

  constructor(
    private _us: UtilService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request)
    .pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMessage = '';
        // server-side error
        // For Token Expiry
        if (error) {
          if (error.status == 0) {
            let loginUrl: string = RoutesContants.formRoutes.signin;
            this._us.navigate(loginUrl);
            localStorage.clear();
            sessionStorage.clear();
            return;
          } else {
            errorMessage = `Error Status: ${error.status}\nMessage: ${error.message}`;
            this._us.OpenAlert(errorMessage, 'error');
          }
        }
        console.log(errorMessage);
        return throwError(errorMessage);
      })
    )
  }


}
