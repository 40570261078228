

export class RoutesContants {

  public static formRoutes = {
    signup: '/signup',
    signin: '/signin',
    home: '/home',
    applyJob: '/apply-job/',
    findJobs:'/find-jobs',
    jobDetails: '/job-details/',
    postJob: '/post-job',
    editJob: '/edit-job/',
    jobsProposals: '/jobs-proposals',
    jobsProposalCandidateDetails: '/jobs-proposal-candidate-details',
    findWorkers: '/find-workers',
    workerJobs: '/worker-jobs',
    workerDetails: '/worker-details/',
    workerSelfDetails: '/worker-jobs',
    editWorkerProfile: '/edit-worker-profile',
    editCustomerProfile: '/edit-customer-profile',
    addReview: '/add-review/',
    messages: '/messages',
    addBooking: '/add-booking/',
    jobManagement: '/job-management',
    favoriteList: '/favorite-list'
  }

  public static configRoutes = {
    signup: 'signup',
    signin: 'signin',
    home: 'home',
    applyJob: 'apply-job',
    findJobs:'find-jobs',
    jobDetails: 'job-details',
    postJob: 'post-job',
    editJob: 'edit-job',
    jobsProposals: 'jobs-proposals',
    jobsProposalCandidateDetails: 'jobs-proposal-candidate-details',
    findWorkers: 'find-workers',
    workerJobs: 'worker-jobs',
    workerDetails: 'worker-details',
    workerSelfDetails: 'worker-jobs',
    editWorkerProfile: 'edit-worker-profile',
    editCustomerProfile: 'edit-customer-profile',
    addReview: 'add-review',
    messages: 'messages',
    addBooking: 'add-booking',
    jobManagement: 'job-management',
    favoriteList: 'favorite-list'
  }

}
