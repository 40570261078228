import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from './core/components/layout/layout.component';
import { RoutesContants } from './core/constants/routes.constant';

const routes: Routes = [
  { path: 'signin', loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule) },
  { path: 'signup', loadChildren: () => import('./modules/signup/signup.module').then(m => m.SignupModule) },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: RoutesContants.configRoutes.home,
        loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule)
      },
      {
        path: RoutesContants.configRoutes.findJobs,
        loadChildren: () => import('./modules/find-jobs/find-jobs.module').then(m => m.FindJobsModule)
      },
      {
        path: RoutesContants.configRoutes.jobDetails,
        loadChildren: () => import('./modules/job-details/job-details.module').then(m => m.JobDetailsModule)
      },
      {
        path: RoutesContants.configRoutes.applyJob,
        loadChildren: () => import('./modules/apply-job/apply-job.module').then(m => m.ApplyJobModule)
      },
      {
        path: RoutesContants.configRoutes.postJob,
        loadChildren: () => import('./modules/post-job/post-job.module').then(m => m.PostJobModule)
      },
      {
        path: `${RoutesContants.configRoutes.editJob}/:jobId`,
        loadChildren: () => import('./modules/post-job/post-job.module').then(m => m.PostJobModule)
      },
      {
        path: RoutesContants.configRoutes.jobsProposals,
        loadChildren: () => import('./modules/job-post-and-proposals/job-post-and-proposals.module').then(m => m.JobPostAndProposalsModule)
      },
      {
        path: RoutesContants.configRoutes.jobsProposalCandidateDetails,
        loadChildren: () => import('./modules/proposal-condidate-detail/proposal-condidate-detail.module').then(m => m.ProposalCondidateDetailModule)
      },
      {
        path: RoutesContants.configRoutes.findWorkers,
        loadChildren: () => import('./modules/find-workers/find-workers.module').then(m => m.FindWorkersModule)
      },
      // {
      //   path: 'worker-jobs',
      //   loadChildren: () => import('./modules/worker-jobs/worker-jobs.module').then(m => m.WorkerJobsModule)
      // },
      {
        path: RoutesContants.configRoutes.workerDetails,
        loadChildren: () => import('./modules/worker-details/worker-details.module').then(m => m.WorkerDetailsModule)
      },
      {
        path: RoutesContants.configRoutes.workerJobs,
        loadChildren: () => import('./modules/worker-self-details/worker-self-details.module').then(m => m.WorkerSelfDetailsModule)
      },
      {
        path: RoutesContants.configRoutes.editWorkerProfile,
        loadChildren: () => import('./modules/edit-worker-profile/edit-worker-profile.module').then(m => m.EditWorkerProfileModule)
      },
      {
        path: RoutesContants.configRoutes.addReview,
        loadChildren: () => import('./modules/add-review/add-review.module').then(m => m.AddReviewModule)
      },
      {
        path: RoutesContants.configRoutes.messages,
        loadChildren: () => import('./modules/chat/chat.module').then(m => m.ChatModule)
      },
      {
        path: RoutesContants.configRoutes.addBooking,
        loadChildren: () => import('./modules/add-booking/add-booking.module').then(m => m.AddBookingModule)
      },
      {
        path: RoutesContants.configRoutes.jobManagement,
        loadChildren: () => import('./modules/jobs-manager/jobs-manager.module').then(m => m.JobsManagerModule)
      },
      {
        path: RoutesContants.configRoutes.editCustomerProfile,
        loadChildren: () => import('./modules/edit-customer-profile/edit-customer-profile.module').then(m => m.EditCustomerProfileModule)
      },
      {
        path: RoutesContants.configRoutes.favoriteList,
        loadChildren: () => import('./modules/favorites-list/favorites-list.module').then(m => m.FavoritesListModule)
      },

    ]
  },
  // {
  //   path: '',
  //   component: LayoutComponent,
  //   children: [
  //     {
  //       path: 'find-jobs',
  //       loadChildren: () => import('./modules/find-jobs/find-jobs.module').then(m => m.FindJobsModule)
  //     }
  //   ]
  // },
  // {
  //   path: '',
  //   component: LayoutComponent,
  //   children: [
  //     {
  //       path: 'job-details',
  //       loadChildren: () => import('./modules/job-details/job-details.module').then(m => m.JobDetailsModule)
  //     }
  //   ]
  // },
  // {
  //   path: '',
  //   component: LayoutComponent,
  //   children: [
  //     {
  //       path: 'apply-job',
  //       loadChildren: () => import('./modules/apply-job/apply-job.module').then(m => m.ApplyJobModule)
  //     }
  //   ]
  // },
  // {
  //   path: '',
  //   component: LayoutComponent,
  //   children: [
  //     {
  //       path: 'post-job',
  //       loadChildren: () => import('./modules/post-job/post-job.module').then(m => m.PostJobModule)
  //     }
  //   ]
  // },
  // {
  //   path: '',
  //   component: LayoutComponent,
  //   children: [
  //     {
  //       path: 'find-workers',
  //       loadChildren: () => import('./modules/find-workers/find-workers.module').then(m => m.FindWorkersModule)
  //     }
  //   ]
  // },
  // {
  //   path: '',
  //   component: LayoutComponent,
  //   children: [
  //     {
  //       path: 'worker-jobs',
  //       loadChildren: () => import('./modules/worker-jobs/worker-jobs.module').then(m => m.WorkerJobsModule)
  //     }
  //   ]
  // },
  {
    path: '**',
    redirectTo: 'home',
    pathMatch: 'full'
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
