import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiConstants } from '../constants/api.constant';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(
    private _http: HttpClient
  ) { }

  /*
    To make http GET request
    @Params
    url: string
  */
  get = (url: string): Observable<any> => {
    return this._http.get(this.createUrl(url), { headers: this.getHeaders() })
  }

  /*
    To make http POST request
    @Params
    url: string
    body: any
  */
  post = (url: string, body: any): Observable<any> => {
    //console.log('api Url : ',url)
    return this._http.post(this.createUrl(url), body, { headers: this.getHeaders()});
  }

  /*
    To make http PUT request
    @Params
    url: string
    body: any
  */
  put = (url: string, body: any): Observable<any> => {
    return this._http.put(this.createUrl(url), body, { headers: this.getHeaders() })
  }

  /*
    To make http DELETE request
    @Params
    url: string
  */
  delete = (url: string): any => {
    return this._http.delete(this.createUrl(url), { headers: this.getHeaders() });
  }

  login = (cridentials: any): Observable<any> => {
    return this._http.put(this.createUrl(ApiConstants.routes.login), cridentials);
  }

  /*
    Defining Http Headers for Http requests
  */
  private getHeaders = () => {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('Access-Control-Allow-Headers','*');
    headers.append('Access-Control-Allow-Origin', '*');
    return headers;
  }

  /*
    Creating complete api url by concatenating basedUrl + api url.
  */
  private createUrl = (url: string): string => {
    return environment.baseUrl+ url;
  }

}
