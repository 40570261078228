import { Component } from '@angular/core';
import { UtilService } from './core/services/util.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'skill-share-rewamp';
  isLoader: boolean;
  constructor(private _us: UtilService) {
    this._us.isLoading.subscribe(x => {
      this.isLoader = x;
    })
  }
}
