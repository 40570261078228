
<app-header></app-header>



<div class="page-wrapper">
  <router-outlet></router-outlet>
</div><!-- End Page Wrapper -->


<app-footer></app-footer>
